import "./styles/Triangle.css";

const Triangle = () => {
  return (
    <div className="Triangle">
      <div className="Background"/>
    </div>
  );
}

export default Triangle;