import { useTranslation } from 'react-i18next';
import Button from '../atoms/Button';
import './styles/BlocPresentation.css';

type Props = {
  onClick: () => void
}
const BlocPresentation = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="BlocPresentation">
      <div className='pictures'>
        <div>
          <img src={'./img/pictures/' + t("presentation.name_1") + '.png'} alt="Equipe" />
          <p>{t("presentation.name_1")}</p>
        </div>
        <div>
          <img src={'./img/pictures/' + t("presentation.name_2") + '.png'} alt="Equipe" />
          <p>{t("presentation.name_2")}</p>
        </div>
      </div>
      <div className='description'>
        <h2 className='bold'>{t("presentation.title")}</h2>
        <p>{t("presentation.content")}</p>
        <Button text={t("other.button")} onClick={onClick} className={"alter"} />
      </div>
    </div>
  );
};

export default BlocPresentation;