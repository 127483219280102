import input from "../../utils/input";
import "./styles/Input.css";

type Props = {
  id: string;
  type?: string;
  onChange: (value: string) => void;
  input: input;
};

const Input = ({ id, type = "text", onChange, input }: Props) => {
  const handleInputChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <div className={`Input field field_v1 ${!input.isValid ? "invalid" : ""}`}>
      <label htmlFor={id} className="ha-screen-reader">
        {input.label}
      </label>
      {type !== "textarea" ? (
        <input
          type={type}
          id={id}
          className="field__input"
          placeholder=" "
          onChange={(event) => handleInputChange(event.target.value)}
          autoComplete="on"
        />
      ) : (
        <textarea
          className="field__input"
          id={id}
          placeholder=" "
          onChange={(event) => handleInputChange(event.target.value)}
        />
      )}
      <span className="field__label-wrap" aria-hidden="true">
        <span className={`field__label ${type === "textarea" ? "Textarea" : ""}`}>{input.label}</span>
      </span>
      {!(input.isValid || input.value === null) && (
        <div className="invalid_message">
          {input.validateRules.map((rule, index) => {
            if (!rule.rule.test(input.value!)) {
              return <div key={index}>{rule.text}</div>;
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default Input;
