import i18n, { currentLanguage, languages } from "../../services/i18n";
import SelectLanguage from "../atoms/SelectLangue";
import "./styles/BlocFooter.css";

const BlocFooter = () => {
  return (
    <div className={"BlocFooter"}>
      <div>
      </div>
      <div>
        <p>NES - Électricité</p>
      </div>
      <div>
        <SelectLanguage
          languesDispos={languages}
          defaultLangue={currentLanguage}
          onChange={(language: string) => i18n.changeLanguage(language)} />
      </div>
    </div>
  );
}

export default BlocFooter;
