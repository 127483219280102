import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

import "./styles/Map.css";
import { useTranslation } from 'react-i18next';

const companyLocation = {
  lat: parseFloat(process.env.REACT_APP_LATITUDE!),
  lng: parseFloat(process.env.REACT_APP_LONGITUDE!)
};

function Map() {
  const [containerStyle, setContainerStyle] = useState<{
    width: string,
    height: string
  }>()

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 1400px)").matches) {
        setContainerStyle({
          width: '400px',
          height: '300px'
        });
      } else if (window.matchMedia("(min-width: 1008px) and (max-width: 1399px)").matches){
        setContainerStyle({
          width: '350px',
          height: '250px'
        });
      } else if (window.matchMedia("(min-width: 641px) and (max-width: 1007px)").matches) {
        setContainerStyle({
          width: '450px',
          height: '250px'
        });
      } else {
        setContainerStyle({
          width: '300px',
          height: '200px'
        });
      }
    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS!
  });

  const [infoWindowOpen, setInfoWindowOpen] = useState(true);

  const handleMarkerClick = () => {
    setInfoWindowOpen(!infoWindowOpen);
  };

  return (
    <div className="Map">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={companyLocation}
          zoom={14}
        >
          {/* Marqueur pour l'emplacement de l'entreprise */}
          <Marker
            position={companyLocation}
            title="NES"
            onClick={handleMarkerClick}
          />

          {/* Infobulle pour l'adresse de l'entreprise */}
          {infoWindowOpen && (
            <InfoWindow
              position={companyLocation}
              onCloseClick={() => setInfoWindowOpen(false)}
            >
              <div className='Address'>
                <p>{t("contact.adresse.value.street")}</p>
                <p>{t("contact.adresse.value.district")}</p>
                <p>{t("contact.adresse.value.city")}</p>
              </div>
            </InfoWindow>
          )}

          {/* Autres composants enfants, tels que les marqueurs, les fenêtres d'information, etc. */}
        </GoogleMap>
      ) : <></>}
    </div>
  );
}


export default React.memo(Map);
