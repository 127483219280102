import "./styles/Button.css";

type Props = {
  onClick: () => void,
  text: string,
  isValid?: boolean;
  className?: "alter" | "dark"
}

const Button = ({ onClick, text, isValid=true, className }: Props) => {
  return (
    <div className={"Button " + (!isValid ? "disabled" : "") + (className ?? "")}>
      <button onClick={() => onClick()} disabled={!isValid}>{text}</button>
    </div>
  );
}

export default Button;
