import { useState } from "react";

import "./styles/Home.css";

import ButtonShutter from "../atoms/ButtonShutter";
import ShutterProject from "../organisms/ShutterProject";
import BlocHeader from "../organisms/BlocHeader";

import Triangle from "../atoms/Triangle";
import Bloc from "../organisms/Bloc";
import BlocContact from "../organisms/BlocContact";
import BlocTrust from "../organisms/BlocTrust";
import BlocPresentation from "../organisms/BlocPresentation";
import BlocServices from "../organisms/BlocServices";
import BlocFooter from "../organisms/BlocFooter";

const Home = () => {
  const [isShutterOpen, setShutterOpen] = useState<boolean>(false);
  
  function handleShutter() {
    setShutterOpen(!isShutterOpen);
  }

  const addShutter = () => (
    <>
      <ButtonShutter onClick={handleShutter} isShutterOpen={isShutterOpen} />
      <ShutterProject isShutterOpen={isShutterOpen} />
    </>
  );

  return (
    <>
      {/* Shutter Contact */}
      {addShutter()}

      {/* Page content */}
      <div className="Home" onClick={() => { if (isShutterOpen) setShutterOpen(false)}}>
        <BlocHeader/>
        <Bloc>
          <Triangle/> 
          <BlocPresentation onClick={() => setShutterOpen(true)}/>
        </Bloc>
        <Bloc color="secondary">
          <BlocServices onClick={() => setShutterOpen(true)}/>
        </Bloc>
        <Bloc>
          <BlocTrust/>
        </Bloc>
        <Bloc color="secondary">
          <BlocContact onClick={() => setShutterOpen(true)}/>
        </Bloc>
        <BlocFooter/>
      </div>

    </>

  );
}

export default Home;