import "./styles/BlocHeader.css";

const BlocHeader = () => {
  return (
    <div className={"BlocHeader"}>
      <img src="./img/logo.svg" alt="logo" />
    </div>
  );
}

export default BlocHeader;