import { useTranslation } from 'react-i18next';
import Button from '../atoms/Button';
import './styles/BlocServices.css';

type Props = {
  onClick: () => void;
}

const BlocServices = ({onClick} : Props) => {
  const { t } = useTranslation();
  const services = t("services.list", {returnObjects: true}) as [];
  return (
    <div className="BlocServices">
      <div className='services'>
        <h2 className='bold'>{t("services.title.regular")}<span className='svg-underline'>{t("services.title.underline")}</span></h2>
        <ul> {
            services.map((service, index) => {
              return (
                <li key={index}>{service}</li>
              )
            })
          
        }</ul>
        <Button text={t("other.button")} onClick={onClick} className="dark"/>
      </div>
      <div className='pictures'>
        <div>
          <img src={"./img/" + t("services.picture_1.src")} alt={t("services.picture_1.alt")}/>
        </div>
      </div>
    </div>
  );
};

export default BlocServices;