import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore"; 

import mail from "../utils/mail";

class Firebase {
  private static instance: Firebase | null = null;
  private app: FirebaseApp;

  private constructor() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    this.app = initializeApp(firebaseConfig);
  }

  static getInstance(): Firebase {
    if (!this.instance) {
      this.instance = new Firebase();
    }

    return this.instance;
  }

  public async sendEmail(emailData: mail): Promise<boolean> {
    const db = getFirestore(this.app);
    const mailCollection = collection(db, "mail");

    try {
      await addDoc(mailCollection, emailData);
      return true;
    } catch (error) {
      console.error("Erreur lors de l'ajout du document :", error);
      return false;
    }
  }
}

export default Firebase;