import "./styles/ButtonShutter.css";
import { useTranslation } from "react-i18next";

type Props = {
  onClick: Function;
  isShutterOpen: boolean;
}

const ButtonShutter = ({ onClick, isShutterOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={"ButtonShutter" + (isShutterOpen ? " open" : " close")} onClick={() => onClick()}>
      <div className="triangle">
        <div className="project-btn">

          <span className={isShutterOpen ? "hide" : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
            </svg>
            <p>{t("shutter.button_toggle")}</p>
          </span>

          <span className={isShutterOpen ? "" : "hide"}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd"></path>
            </svg>
          </span> 
          
        </div>
      </div>
    </div>
  );
}

export default ButtonShutter;
