import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Dynamic import of different translation files
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE ?? "en-US";
const translations = require.context('../translation', false, /\.json$/);
const keys: string[] = translations.keys();
const regexAllLetters: RegExp = /(?<=\.\/).+?(?=\.json)/;

// Definition of the list of available languages
export const languages: Record<string, string> = Object.fromEntries(
  keys
    .reduce((acc, key) => {
      const allLetters = key.match(regexAllLetters)?.[0] ?? null;

      if (allLetters != null && allLetters) {
        acc.push([allLetters, allLetters]);
      }
      return acc;
    }, [] as [string, string][])
    .sort(([a], [b]) => a.localeCompare(b))
);

export const currentLanguage = 
  Object.keys(languages).includes(navigator.language) 
  ? navigator.language 
  : defaultLanguage;

// Reading loaded translation files
const resources: Record<string, any> = {};
translations.keys().forEach(key => {
  const langKey = key.replace('./', '').replace('.json', '');
  resources[langKey] = translations(key);
});

// Initializing i18n settings
i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: languages[navigator.language] || defaultLanguage,
    debug: false,
    defaultNS: 'react',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
