import Form from "../molecules/Form";
import "./styles/ShutterProject.css";

type Props = {
  isShutterOpen: boolean;
  children?: React.ReactNode;
}

const ShutterProject = ({isShutterOpen} : Props) => {
  return (
    <div className={"ShutterProject" + (isShutterOpen ? "" : " close")}>
      <Form/>
    </div>
  );
}

export default ShutterProject;