import { useEffect, useState } from "react";
import Input from "../atoms/Input";

import "./styles/Form.css";

import Button from "../atoms/Button";
import Firebase from "../../services/Firebase";
import mail from "../../utils/mail";
import input from "../../utils/input";

import { useTranslation } from 'react-i18next';

const Form = () => {
  const { t } = useTranslation();

const initialFields: Record<string, input> = {
  'name': {
    type: "text",
    label: t("shutter.input.name.label"),
    value: null,
    isValid: null,
    validateRules: [
      {
        rule: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        text: t("shutter.input.name.invalid")
      }
    ]
  },
  'lastName': {
    type: "text",
    label: t("shutter.input.lastName.label"),
    value: null,
    isValid: null,
    validateRules: [
      {
        rule: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
        text: t("shutter.input.name.invalid")
      }
    ]
  },
  'email': {
    type: t("shutter.input.mailAdress.label"),
    label: "Adresse mail",
    value: null,
    isValid: null,
    validateRules: [
      {
        rule: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        text: t("shutter.input.mailAdress.invalid")
      }
    ]
  },
  'phone': {
    type: "tel",
    label: t("shutter.input.phone.label"),
    value: null,
    isValid: null,
    validateRules: [
      {
        rule: /(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/,
        text: t("shutter.input.phone.invalid")
      }
    ]
  },
  'company': {
    type: "text",
    label: t("shutter.input.company.label"),
    value: null,
    isValid: null,
    validateRules: [
      {
        rule: /^(?!\s*$).+/,
        text: t("shutter.input.company.label")
      }
    ]
  },
  'adresse': {
    type: "text",
    label: t("shutter.input.adress.label"),
    value: null,
    isValid: null,
    validateRules: [
      {
        rule: /^(\d+)\s+([^\d]+)\s+(\d{5})\s+([^\d]+)$/,
        text: t("shutter.input.adress.label")
      }
    ]
  },
  'message': {
    type: "textarea",
    label: t("shutter.input.text.label"),
    value: null,
    isValid: null,
    validateRules: [
      {
        rule: /^(?!\s*$).+/,
        text: t("shutter.input.text.label")
      }
    ]
  },
};

  const [fields, setFields] = useState(initialFields);
  const [mailData, setMailData] = useState<mail | null>(null);
  const [isMailSent, setIsMailSent] = useState<boolean>(false);

  const handleInputChange = (key: keyof typeof initialFields, value: string) => {
    setFields(prevFields => {
      const isValid = prevFields[key].validateRules.every(rule => rule.rule.test(value));

      return {
        ...prevFields,
        [key]: { ...prevFields[key], value, isValid },
      };
    });
  };

  useEffect(() => {
    const isFormValid = Object.values(fields).every(field => field.isValid === true);

    if (isFormValid) {
      const mail: mail = {
        to: [process.env.REACT_APP_EMAIL_DESTINATION!],
        message: {
          subject: 'Nouvelle demande de contact !',
          text: `Prénom: ${fields.name.value}\n` +
            `Nom: ${fields.lastName.value}\n` +
            `Téléphone: ${fields.phone.value}\n` +
            `Société: ${fields.company.value}\n` +
            `Adresse: ${fields.adresse.value}\n` +
            `Message: ${fields.message.value}`,
          html: `<p><strong>Prénom:</strong> ${fields.name.value}</p>` +
            `<p><strong>Nom:</strong> ${fields.lastName.value}</p>` +
            `<p><strong>E-mail:</strong> ${fields.email.value}</p>` +
            `<p><strong>Téléphone:</strong> ${fields.phone.value}</p>` +
            `<p><strong>Société:</strong> ${fields.company.value}</p>` +
            `<p><strong>Adresse:</strong> ${fields.adresse.value}</p>` +
            `<p><strong>Message:</strong> ${fields.message.value}</p>`,
        },
      };

      setMailData(mail);
    } else {
      setMailData(null);
    }
  }, [fields]);


  // Envoi du mail
  async function sendMail() {
    const fb = Firebase.getInstance();
    if (mailData) {
      const mailSent = fb.sendEmail(mailData)
      setMailData(null);
      setIsMailSent(await mailSent);
    }
  }

  const formMail = () => (
    <div className={"div_form" + (isMailSent ? " mailSent" : "")}>
      <h2>{t("shutter.title")}</h2>
      <div className="InputList">
        <div className="Raw_1">
          {Object.entries(fields).slice(0, 2).map(([key, field]) => (
            <Input
              key={key}
              id={key}
              type={field.type}
              onChange={(value) => handleInputChange(key, value)}
              input={field}
            />
          ))}
        </div>
        <div className="Other">
          {Object.entries(fields).slice(2).map(([key, field]) => (
            <Input
              key={key}
              id={key}
              type={field.type}
              onChange={(value) => handleInputChange(key, value)}
              input={field}
            />
          ))}
        </div>
        <div className="SubmitForm">
          <Button text={t("shutter.button_submit")} onClick={() => sendMail()} isValid={mailData !== null} />
        </div>
      </div>
    </div>
  );

  const confirmationEmail = () => (
    <div className={"div_confirmation" + (isMailSent ? " mailSent" : "")}>
      <h2>{t("shutter.thanks.title")}</h2>
      <div>
        <p>{t("shutter.thanks.mail")}</p>
        <p>{t("shutter.thanks.recontact")}</p>
      </div>
      <p>{t("shutter.thanks.bye")}</p>
    </div>
  );

  return (
    <div className="Form">
      { formMail() }
      { confirmationEmail() }
    </div>
  );
}

export default Form;
