import './styles/BlocContact.css';
import Map from '../atoms/Map';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void
};

const BlocContact = ({ onClick }: Props) => {

  const { t } = useTranslation();

  return (
    <div className="BlocContact">
      <Map/>
      <div className='information'>
        <h2 className='bold'>{t("contact.title.regular_1")}<span className='svg-underline'>{t("contact.title.underline")}</span>{t("contact.title.regular_2")}</h2>
        <div className='contactList'>
          <p>
            <span className='label'>{t("contact.adresse.label")}</span>
            <span className='info'> {t("contact.adresse.value.street")} - {t("contact.adresse.value.district")} - {t("contact.adresse.value.city")}</span>
          </p>
          <p>
            <span className='label'>{t("contact.phone.label")}</span>
            <span className='info'>{t("contact.phone.value")}</span>
          </p>
          <p>
            <span className='label'>{t("contact.mailAdress.label")}</span>
            <span className='info'>{t("contact.mailAdress.value")}</span>
          </p>
          <p className='form'>
          {t("contact.toggleForm.hook")}<button onClick={onClick}>{t("contact.toggleForm.button")}</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlocContact;