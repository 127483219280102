import { useTranslation } from "react-i18next";
import companie from "../../utils/companie";
import "./styles/BlocTrust.css";


const BlocTrust = () => {
  const { t } = useTranslation();
  const companies = t("trust.companies", {returnObjects: true}) as Array<companie>;

  return (
    <div className={"BlocTrust"}>
      <h2 className="bold">{t("trust.title.regular")}<span className="svg-underline">{t("trust.title.underline")}</span></h2>
      <div className="Companies">{
        companies.map((companie, index) => {
          return (
            <div key={index}>
              <img src={"./img/" + (companie.logo)} alt={companie.name}/>
              <p>{companie.name}</p>
            </div>
          );
        })
      }</div>
    </div>
  );
}

export default BlocTrust;
