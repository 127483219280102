import { ReactNode } from 'react';
import './styles/Bloc.css';

type Props = {
  color?: "main" | "secondary";
  children: ReactNode;
};

const Bloc = ({color, children }: Props) => {
  const className = color !== undefined ? `Bloc ${color}` : 'Bloc';
  
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default Bloc;